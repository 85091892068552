import { CityComponents } from './CityComponents';
import { LangSwitcher } from './LangSwitcher';
import { ReactComponent as Vk } from '../../../../assets/images/Header/vk.svg';
import { ReactComponent as Wu } from '../../../../assets/images/Header/whatsap.svg';

import '../Header.scss';

export const AboutHeader = (): JSX.Element => (
  <div className="wrap-header d-flex align-items-center justify-content-around">
    <div className="header-hr d-none-991" />
    <CityComponents />
    <div className="header-hr"></div>
    <div className="d-grid">
      <a href="tel:+7 473 333-59-95" className="header-tel header-hovered">
        <b>+7 473 333-59-95</b>
      </a>
      <p className="mb-0 header-time">9:00 - 19:00</p>
    </div>
    <div className="header-hr"></div>
    <div className="d-grid">
      <div className="d-flex gap-2 align-content-center justify-content-center">
        <a
          href="https://vk.com/club_event360"
          className="header-social header-hovered"
          target="_blank"
          rel="noreferrer"
        >
          <Vk />
        </a>
        <a
          href="https://wa.me/79202187374"
          className="header-social header-hovered"
          target="_blank"
          rel="noreferrer"
        >
          <Wu width={34} height={34} />
        </a>
      </div>
      <LangSwitcher />
    </div>
  </div>
);
