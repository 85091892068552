import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import btur1 from '../../../../assets/images/Service/деловые туры.jpg';
import korp1 from '../../../../assets/images/Service/корп туры 1.jpg';
import korp2 from '../../../../assets/images/Service/корп туры 2.jpg';
import prom1 from '../../../../assets/images/Service/пром туры 1.jpg';
import prom2 from '../../../../assets/images/Service/пром туры 2.jpg';
import prom3 from '../../../../assets/images/Service/пром туры 3.jpg';
import exstur1 from '../../../../assets/images/Service/Экскурс туры 1.jpg';
import exstur2 from '../../../../assets/images/Service/экскурс туры 2.jpg';
import exstur3 from '../../../../assets/images/Service/экскурс туры 3.jpg';
import { findAndReplaceBreak } from '../../../../shared/utils/findAndReplaceBreak';

interface IProps {
  id?: string;
}

export const ServiceAbout = ({ id }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const areaAbout = [
    {
      id: '1',
      text: t('Service.Items.item1'),
      images: [],
    },
    {
      id: '2',
      text: t('Service.Items.item2'),
      images: [],
    },
    {
      id: '3',
      text: t('Service.Items.item3'),
      images: [],
    },
    {
      id: '4',
      text: t('Service.Items.item4'),
      images: [],
    },
    {
      id: '5',
      text: t('Service.Items.item5'),
      images: [],
    },
    {
      id: '6',
      text: t('Service.Items.item6'),
      images: [],
    },
    {
      id: '7',
      text: t('Service.Items.item7'),
      images: [],
    },
    {
      id: '8',
      text: t('Service.Items.item8'),
      images: [],
    },
    {
      id: '9',
      text: t('Service.Items.item9'),
      images: [{ id: 1, images: btur1 }],
    },
    {
      id: '10',
      text: t('Service.Items.item10'),
      images: [
        { id: 1, images: prom1 },
        { id: 2, images: prom2 },
        { id: 3, images: prom3 },
      ],
    },
    {
      id: '11',
      text: t('Service.Items.item11'),
      images: [
        { id: 1, images: korp1 },
        { id: 2, images: korp2 },
      ],
    },
    {
      id: '12',
      text: t('Service.Items.item12'),
      images: [
        { id: 1, images: exstur1 },
        { id: 2, images: exstur2 },
        { id: 3, images: exstur3 },
      ],
      button: {
        href: '/download/catalog.pdf',
        title: t('Service.other.title'),
      },
    },
  ];

  const getById = (id: string, item: string, children?: string) => {
    const find = areaAbout.find((item) => item.id === id);

    if (find) {
      return children ? find[item][children] : find[item];
    }
  };

  return (
    <div className="container d-grid gap-2 mt-5 mb-5">
      <Link to={'/service'}>{t('Service.back')}</Link>
      <div className="text-wrapper">
        {findAndReplaceBreak(getById(id, 'text'), true)}
        {t('Service.reest.text')}{' '}
        <a
          href="https://tourism.gov.ru/reestry/reestr-turoperatorov/show.php?id=114525"
          target="_blank"
          rel="noreferrer"
        >
          {t('Service.reest.href')}
        </a>
      </div>
      <div>
        <div className="d-flex flex-wrap gap-2">
          {getById(id, 'images').map(({ id, images }) => (
            <img key={id} className="about-image" src={images} alt="" />
          ))}
        </div>
        {getById(id, 'button') && (
          <div className="download">
            <a
              className="download-button-service"
              href={getById(id, 'button', 'href')}
              download={`${getById(id, 'button', 'title')}.pdf`}
            >
              {getById(id, 'button', 'title')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
