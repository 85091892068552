import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SliderProject } from './SliderProject';
// import { fetchPOST } from '../../../shared/utils/fetchPOST';
import { findAndReplaceBreak } from '../../../shared/utils/findAndReplaceBreak';

import './Projects.scss';

interface IProps {
  id?: string;
  id_card: number;
  title: string;
  date: string;
  text: string;
  images: string | string[];
}

export const ProjectsItem = ({
  id,
  // id_card,
  title,
  date,
  text,
  images,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  // const [change, setChange] = useState(text);

  // const sendHandler = async () => {
  //   fetchPOST({
  //     body: { id: id_card, text: change, title: title, date: date },
  //     uri: 'saver',
  //   });
  //   setOpen(false);
  // };

  // const changeHandler = (e) => {
  //   const { value } = e.target;

  //   setChange(value);
  // };

  // const [open, setOpen] = useState(false);

  // const openMenuHandler = () => {
  //   setOpen((prev) => !prev);
  // };

  return (
    <div className="container" id={id}>
      <div className="project-wrapper">
        {typeof images !== 'string' ? (
          <SliderProject images={images} />
        ) : (
          <img className="images-project" src={images} />
        )}

        <div className={show ? 'text-block active' : 'text-block'}>
          <p className="title-variant">
            <b>{title}</b>
          </p>
          <p>{date}</p>
          <p>{findAndReplaceBreak(text, true)}</p>
          <div
            className={show ? 'hidden' : 'shadows'}
            onClick={() => setShow(!show)}
          >
            {show ? t('Project.button.collapse') : t('Project.button.next')}
          </div>
        </div>
      </div>
      {/* {open && (
        <>
          <textarea
            rows={10}
            cols={74}
            defaultValue={text}
            onChange={changeHandler}
          />
          <button onClick={sendHandler}>send</button>
        </>
      )} */}
    </div>
  );
};
