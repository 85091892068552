import cn from 'classname';
import { useState } from 'react';
import './SliderProject.scss';

interface IProps {
  images: string[];
}

export const SliderProject = ({ images }: IProps): JSX.Element => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide((prev) => (prev + 1 > images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setSlide((prev) => (prev - 1 < 0 ? images.length - 1 : prev - 1));
  };

  return (
    <div className="project-slider">
      {images.map((item, index) => (
        <img
          key={index}
          className={cn(
            'images-project',
            'slideProject',
            slide === index && 'active',
          )}
          src={item}
        />
      ))}
      <div
        onClick={prevSlide}
        className={cn('slideProject', 'button prev-button')}
      >
        {'<'}
      </div>
      <div
        onClick={nextSlide}
        className={cn('slideProject', 'button next-button')}
      >
        {'>'}
      </div>
    </div>
  );
};
